import { UserApi } from 'mik-common/src/api-autogen/globalmenu/api/user-api'

import { PersonalizationManagementEntity } from '@gnb/models/user/personalization/personalization-management-entity'
import { defineStore } from 'pinia'
const apiUser = new UserApi()
export const usePersonalizationStore = defineStore('personalization', {
  persist: [
    {
      paths: ['personalization'],
      storage: sessionStorage
    }
  ],
  state: () => {
    return {
      personalization: {} as PersonalizationManagementEntity
    }
  },
  getters: {
    getPersonalization: (state) => {
      return state.personalization
    }
  },
  actions: {
    personalizationInit() {
      const response = apiUser.v1UserPersonalizationGet()

      response.then(({ data }) => {
        if (data.success && data.data) {
          this.personalization = data.data as PersonalizationManagementEntity
        }
      })
    }
  }
})
